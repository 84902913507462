import React from "react"
import { Container, Header } from "semantic-ui-react"

const FeedPanel = () => {
  return (
    <div className="padded-container">
      <Container>
        <Header size="large">Instagram Feed</Header>
      </Container>
    </div>
  )
}

export default FeedPanel
