import React from "react"
import Img from "gatsby-image"
import { Container, Header, Grid } from "semantic-ui-react"

const servicesCard = (image, title, body) => {
  return (
    <Grid.Column>
      <Img fluid={image} />
      <Header>{title}</Header>
      {body}
    </Grid.Column>
  )
}

const ServicesPanel = props => {
  const { videoBlurb, videoTitle, videoImage } = props.services.videoProduction
  const {
    photographyImage,
    photographyTitle,
    photographyBlurb,
  } = props.services.photography
  const { webDevBlurb, webDevTitle, webDevImage } = props.services.webDev
  const {
    graphicDesignBlurb,
    graphicDesignTitle,
    graphicDesignImage,
  } = props.services.graphicDesign
  const {
    socialMediaBlurb,
    socialMediaTitle,
    socialMediaImage,
  } = props.services.socialMedia
  const {
    marketingBlurb,
    marketingTitle,
    marketingImage,
  } = props.services.marketing
  return (
    <div className="padded-container">
      <Container>
        <Header size="large">Services</Header>

        <Grid stackable columns={3}>
          <Grid.Row>
            {servicesCard(
              videoImage.childImageSharp.fluid,
              videoTitle,
              videoBlurb
            )}
            {servicesCard(
              photographyImage.childImageSharp.fluid,
              photographyTitle,
              photographyBlurb
            )}
            {servicesCard(
              webDevImage.childImageSharp.fluid,
              webDevTitle,
              webDevBlurb
            )}
          </Grid.Row>

          <Grid.Row>
            {servicesCard(
              graphicDesignImage.childImageSharp.fluid,
              graphicDesignTitle,
              graphicDesignBlurb
            )}
            {servicesCard(
              socialMediaImage.childImageSharp.fluid,
              socialMediaTitle,
              socialMediaBlurb
            )}
            {servicesCard(
              marketingImage.childImageSharp.fluid,
              marketingTitle,
              marketingBlurb
            )}
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default ServicesPanel
