import React from "react"
import { Container, Header } from "semantic-ui-react"
import ProjectRoll from "../../ProjectRoll"

const PortfolioPanel = () => {
  return (
    <div className="padded-container">
      <Container>
        <Header size="large">Portfolio</Header>
        <ProjectRoll />
      </Container>
    </div>
  )
}

export default PortfolioPanel
