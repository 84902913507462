import React from "react"
import { Container, Header } from "semantic-ui-react"

const SowPanel = props => {
  return (
    <div className="padded-container">
      <Container>
        <Header size="large">Description of Work</Header>
        {props.sow}
      </Container>
    </div>
  )
}

export default SowPanel
