import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Card, Grid } from "semantic-ui-react"

class ProjectRoll extends Component {
  buildCard = card => {
    const { image, title } = card.node.frontmatter
    const { slug } = card.node.fields
    return (
      <Card key={title}>
        <Img fluid={image.childImageSharp.fluid} />
        <Card.Content>
          <Card.Header>{title}</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Link to={slug}>See More</Link>
        </Card.Content>
      </Card>
    )
  }

  buildGrid = cards => {
    const list = cards.map(card => this.buildCard(card))
    const rows = []
    const step = 3
    for (let i = 0, j = 0; i < list.length; i += step, j++) {
      const segment = list.slice(i * j, i * j + step)
      const segmentCols = segment.map((seg, i) => (
        <Grid.Column key={i}>{seg}</Grid.Column>
      ))
      rows.push(segmentCols)
    }

    return (
      <Grid stackable columns={step}>
        {rows}
      </Grid>
    )
  }

  render() {
    const { data } = this.props
    const { edges: projects } = data.allMarkdownRemark

    return projects && this.buildGrid(projects)
  }
}

ProjectRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ProjectRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___title] }
          filter: { frontmatter: { templateKey: { eq: "project-page" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 250, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ProjectRoll data={data} count={count} />}
  />
)
