import React from "react"
import { Container, Header, Button } from "semantic-ui-react"

const VideoPanel = props => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        overflow: "hidden",
        padding: "2rem",
      }}
    >
      <video
        autoPlay
        muted
        loop
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "-1",
        }}
      >
        <source src={props.video.publicURL} type="video/mp4" />
      </video>
      <Container
        style={{
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <Header size="huge">Iris Effect Media</Header>
        <Button>Testing</Button>
      </Container>
    </div>
  )
}

export default VideoPanel
