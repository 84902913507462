import React from "react"
import { graphql } from "gatsby"
// import { Header, Container, Button } from "semantic-ui-react"
import PropTypes from "prop-types"
import "semantic-ui-css/semantic.min.css"

import SEO from "../components/seo"
import Layout from "../components/Layout"

import VideoPanel from "../components/Home/VideoPanel"
import MottoPanel from "../components/Home/MottoPanel"
import SowPanel from "../components/Home/SowPanel"
import ServicesPanel from "../components/Home/ServicesPanel"
import PortfolioPanel from "../components/Home/PortfolioPanel"
import TestimonialsPanel from "../components/Home/TestimonialsPanel"
import FeedPanel from "../components/Home/FeedPanel"

// const banner = (image, videoBanner) => {
//   return (
//     <div
//       style={{
//         width: "100vw",
//         height: "100vh",
//         position: "relative",
//         overflow: "hidden",
//         padding: "2rem",
//       }}
//     >
//       <video
//         autoPlay
//         muted
//         loop
//         style={{
//           objectFit: "cover",
//           width: "100%",
//           height: "100%",
//           position: "absolute",
//           top: "0",
//           left: "0",
//           zIndex: "-1",
//         }}
//       >
//         <source src={videoBanner.publicURL} type="video/mp4" />
//       </video>
//       <Container
//         style={{
//           top: "50%",
//           left: "50%",
//           position: "absolute",
//           transform: "translate(-50%, -50%)",
//           textAlign: "center",
//         }}
//       >
//         <Header size="huge">Iris Effect Media</Header>
//         <Button>Testing</Button>
//       </Container>
//       <div
//         className="full-width-image margin-top-0"
//         style={{
//           // backgroundImage: `url(${
//           //   !!image.childImageSharp ? image.childImageSharp.fluid.src : image
//           // })`,
//           backgroundColor: `grey`,
//           backgroundPosition: `top`,
//           backgroundAttachment: `fixed`,
//         }}
//       >
//         {/* <Img fluid={image.childImageSharp.fluid} /> */}
//       </div>
//     </div>
//   )
// }

export const IndexPageTemplate = ({
  image,
  videoBanner,
  motto,
  sow,
  services,
}) => (
  <>
    {/* {banner(image, videoBanner)} */}
    <VideoPanel video={videoBanner} />
    <MottoPanel motto={motto} />
    <SowPanel sow={sow} />
    <ServicesPanel services={services} />
    <PortfolioPanel />
    <TestimonialsPanel />
    <FeedPanel />

    {/* <div
      style={{
        display: "flex",
        height: "150px",
        lineHeight: "1",
        justifyContent: "space-around",
        alignItems: "left",
        flexDirection: "column",
      }}
    >
      <h1
        className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
        style={{
          boxShadow:
            "rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px",
          backgroundColor: "rgb(255, 68, 0)",
          color: "white",
          lineHeight: "1",
          padding: "0.25em",
        }}
      >
        {title}
      </h1>
      <h3
        className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
        style={{
          boxShadow:
            "rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px",
          backgroundColor: "rgb(255, 68, 0)",
          color: "white",
          lineHeight: "1",
          padding: "0.25em",
        }}
      >
        {subheading} 
      </h3> */}
    {/* </div> */}
    {/* <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
  </>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout node="/">
      <SEO title="Home" />
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        videoBanner={frontmatter.videoBanner}
        motto={frontmatter.motto}
        sow={frontmatter.sow}
        services={frontmatter.services}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2048, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          ...fluidImage
        }
        videoBanner {
          publicURL
        }
        motto
        sow
        services {
          videoProduction {
            videoTitle
            videoBlurb
            videoImage {
              ...fluidImage
            }
          }
          photography {
            photographyBlurb
            photographyTitle
            photographyImage {
              ...fluidImage
            }
          }
          webDev {
            webDevBlurb
            webDevTitle
            webDevImage {
              ...fluidImage
            }
          }
          graphicDesign {
            graphicDesignBlurb
            graphicDesignTitle
            graphicDesignImage {
              ...fluidImage
            }
          }
          socialMedia {
            socialMediaBlurb
            socialMediaTitle
            socialMediaImage {
              ...fluidImage
            }
          }
          marketing {
            marketingBlurb
            marketingTitle
            marketingImage {
              ...fluidImage
            }
          }
        }
      }
    }
  }
`
