import React from "react"
import { Container, Header } from "semantic-ui-react"

const TestimonialsPanel = () => {
  return (
    <div className="padded-container">
      <Container>
        <Header size="large">Testimonials</Header>
      </Container>
    </div>
  )
}

export default TestimonialsPanel
