import React from "react"
import { Container, Header } from "semantic-ui-react"

import "../home.scss"

const MottoPanel = props => {
  return (
    <div className="padded-container">
      <Container>
        <Header
          as="h1"
          content={props.motto}
          // style={{
          //   fontSize: mobile ? "2em" : "4em",
          //   fontWeight: "normal",
          //   marginBottom: 0,
          //   marginTop: mobile ? "1.5em" : "3em",
          // }}
        />
      </Container>
    </div>
  )
}

export default MottoPanel
